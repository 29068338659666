import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import Footer from 'screens/public/layout/footer';
import Header from 'screens/public/layout/header';
import { HomeService } from 'services';
import { WhatsappIcon } from 'assets/icons';
import { WhatsappImage } from 'assets/img';

class Layout extends React.Component<any> {

	state = {
		contact_info: null
	}

	componentDidMount() {
		this.loadBanners();
		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await HomeService.public.getContact({
			withoutLoading: true
		});
		if (res.contact_info) {
			this.props.dispatch({
				type: 'SET_CONTACT_INFO',
				payload: res.contact_info
			});
			this.setState({
				contact_info: res.contact_info
			});
		}	
		if (res.last_products) {
			this.props.dispatch({
				type: 'SET_LAST_PRODUCTS',
				payload: res.last_products
			});
		}
		if (res.product_types) {
			this.props.dispatch({
				type: 'SET_PRODUCT_TYPES',
				payload: res.product_types
			});
		}	
		if (res.categories) {
			this.props.dispatch({
				type: 'SET_CATEGORIES',
				payload: res.categories
			});
		}
	}

	loadBanners = async (withoutLoading: boolean = false) => {
		const res: any = await HomeService.public.get({
			withoutLoading: true
		});
		this.props.dispatch({
			type: 'SET_BANNERS',
			payload: res.banners
		});
	}

	render() {
		const contact_info: any = this.state.contact_info;

		return (
			<div id="public-layout">
				<Header />
					<a href={ `https://wa.me/` + contact_info?.whatsapp } target="_blank">
						<button className="btn-whatsapp-footer">
							<div className="container-whatsapp-footer">
								<img src={ WhatsappImage } />
								<p>¿Cómo puedo ayudarte?</p>
							</div>
						</button>
					</a>
					<div id="container-public-layout">
						{ this.props.children }
					</div>
				<Footer />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Layout);